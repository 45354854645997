import { useTranslation } from "@health/i18n";
import { formatGraphQLError, useVendorOrderAcceptMutation } from "@health/queries";
import { useAddToast } from "@health/ui";
import { FC } from "react";
import { MedicationsMappingDialog } from "../cards/MedicationsMappingDialog";
import { OrderMedicationsMappingProps } from "../cards/types";

export interface MedicationsProps extends OrderMedicationsMappingProps {
  orderId: string;
}

export const ViewAndAcceptButton: FC<MedicationsProps> = ({ medications, referenceNumber, patientIdentifier, orderId, branchId }) => {
  const { t } = useTranslation("vendor");
  const { failed, succeeded } = useAddToast();

  const [orderVendorAccept, { loading }] = useVendorOrderAcceptMutation({
    onCompleted: data => {
      if (data?.vendorOrderAccept?.orderErrors?.length === 0) {
        succeeded(t("Order accepted successfully"));
      } else {
        const msg = data?.vendorOrderAccept?.orderErrors[0];
        failed(msg?.field + " " + msg?.message);
      }
    },
    onError: ({ networkError }) => {
      failed(formatGraphQLError(networkError?.["result"]?.Errors));
    },
  });

  const handleOrderAccepted = (acceptedMedications): void => {
    orderVendorAccept({
      variables: {
        id: orderId,
        input: {
          acceptedMedications,
        },
      },
    });
  };

  return (
    <MedicationsMappingDialog
      isLoading={loading}
      medications={medications}
      patientIdentifier={patientIdentifier}
      referenceNumber={referenceNumber}
      branchId={branchId}
      onSubmit={handleOrderAccepted}
      buttonLabel={t("View & Accept")}
    />
  );
};
