import { useTranslation } from "@health/i18n";
import { bg, Box, Button, deepViolet, IconButton, MicrophoneIcon, Tooltip, Typography, white } from "@health/ui";
import React, { FC } from "react";
import { isMobile } from "react-device-detect";
import { useVoiceRecorder } from "../../hooks/useVoiceRecorder";
import { RecorderProps } from "./Recorder.types";

export const Recorder: FC<RecorderProps> = ({ onRecorded }) => {
  const { t } = useTranslation();
  const onSend = (data: Blob) => {
    return onRecorded(data);
  };
  const { isRecording, stop, start, isFailed } = useVoiceRecorder(onSend);

  const handleStart = () => {
    !isFailed && start();
  };
  const handleStop = () => {
    stop();
  };

  return (
    <Box sx={{ display: "flex" }}>
      {!isRecording ? (
        <Tooltip title={() => (isFailed ? t("Please Allow your Browser to Access your Microphone") : t("Microphone"))}>
          <IconButton
            size={"small"}
            onClick={handleStart}
            sx={{
              background: deepViolet,
              width: "35px",
              color: white,
              height: "35px",
              borderRadius: "40px",
              "&:hover": { background: bg, color: deepViolet },
            }}
          >
            <MicrophoneIcon fill={"inherit"} />
          </IconButton>
        </Tooltip>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Button variant={"text"} size={"small"} color={"success"} sx={{ padding: "4px 5px" }} onClick={handleStop}>
            {t("Stop")}
          </Button>
          <Typography sx={{ marginInlineEnd: 4 }}> {!isMobile && t("Recording")}...</Typography>
        </Box>
      )}
    </Box>
  );
};
