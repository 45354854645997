import { useTranslation } from "@health/i18n";
import {
  Alert,
  Badge,
  Box,
  Button,
  CircularProgress,
  cyan,
  Divider,
  IconButton,
  InfiniteScrollContainer,
  NotificationIcon,
  NotificationsDrawer,
  NotificationsItem,
  Snackbar,
  Typography,
} from "@health/ui";
import React from "react";
import { useNotificationsHooks } from "./Notifications.hook";
import { useNotificationsStyles } from "./Notifications.styles";

export const Notifications = () => {
  const { t } = useTranslation();
  const { classes } = useNotificationsStyles();
  const {
    open,
    loading,
    notifications,
    pageInfo,
    totalCount,
    unReadNotificationsCount,
    unseenNewOrdersNotificationsCount,
    handleToggle,
    fetchMoreData,
    autoplayBlocked,
    handleAllowSound,
  } = useNotificationsHooks();

  return (
    <>
      <IconButton color='primary' onClick={handleToggle}>
        <Badge badgeContent={unReadNotificationsCount} color='error'>
          <NotificationIcon />
        </Badge>
      </IconButton>
      {open && (
        <NotificationsDrawer open={open} number={Number(totalCount)} onClose={handleToggle}>
          {loading ? (
            <Box display='flex' justifyContent='center' color={cyan} m={1}>
              <CircularProgress size={50} color='inherit' />
            </Box>
          ) : (
            <>
              {unReadNotificationsCount ? (
                <div className={classes.container} id='scrollableDiv'>
                  {unseenNewOrdersNotificationsCount > 0 && (
                    <>
                      <NotificationsItem
                        title={t("New Orders")}
                        subTitle={
                          unseenNewOrdersNotificationsCount == 1
                            ? t("You have a new order")
                            : t("You have {{value}} new orders", { value: unseenNewOrdersNotificationsCount })
                        }
                        type='success'
                      />
                      <Divider />
                    </>
                  )}
                  <InfiniteScrollContainer
                    dataLength={Number(notifications?.length) || 0}
                    next={() => fetchMoreData()}
                    hasMore={Boolean(pageInfo?.hasNextPage)}
                    loader={
                      <Box display='flex' justifyContent='center' color={cyan} m={1}>
                        <CircularProgress size={50} color='inherit' />
                      </Box>
                    }
                    classes={{
                      infinite: classes.infinite,
                    }}
                    scrollableTarget='scrollableDiv'
                  >
                    {notifications?.map(notification => (
                      <NotificationsItem
                        key={notification.id}
                        title={String(notification.title)}
                        subTitle={String(notification.body)}
                        type={notification.type}
                      />
                    ))}
                  </InfiniteScrollContainer>
                </div>
              ) : (
                <Typography p={3} variant='h5'>
                  {t("There is no Notifications")}
                </Typography>
              )}
            </>
          )}
        </NotificationsDrawer>
      )}
      <Snackbar
        open={autoplayBlocked}
        autoHideDuration={null}
        sx={{ zIndex: 9999 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          severity='warning'
          action={
            <Button color='primary' size='small' onClick={handleAllowSound}>
              {t("Allow")}
            </Button>
          }
        >
          {t("Autoplay is blocked. Click `Allow` to enable sound.")}
        </Alert>
      </Snackbar>
    </>
  );
};
