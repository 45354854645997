/* eslint-disable max-statements */
import { makeVar, useReactiveVar } from "@apollo/client";
import {
  AccountNotificationEvent,
  areStatuesEqual,
  EVENT_TOPIC,
  OrderStatusChangedNotificationEvent,
  TOPIC_LISTENER,
  UserContext,
  useSubscribeToNotificationTopic,
} from "@health/common";
import { useTranslation } from "@health/i18n";
import { MeNotificationsQuery, OrderStatus, useMarkNotificationsAsSeenMutation, useMeNotificationsQuery } from "@health/queries";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { pharmacyUserPreferencesVar } from "../PharmacyBranch";
import { notify } from "./notifyDesktop";
import { usePlayAudioHook } from "./usePlayAudioHook";

const notificationSoundFiles = makeVar<Record<string, HTMLAudioElement>>({});

export const useNotificationsHooks = () => {
  const { t } = useTranslation("vendor");
  const { data, loading, fetchMore, refetch } = useMeNotificationsQuery({
    variables: {
      first: 10,
    },
  });

  const [notifications, setNotifications] = useState<any | undefined>([]);

  const { autoplayBlocked, handleAllowSound, playAudio } = usePlayAudioHook({ notificationSoundFiles });

  const [totalCount, setTotalCount] = useState<number | undefined>(0); // Update total count based on filtered notifications
  const [unseenNewOrdersNotificationsCount, setUnseenNewOrdersNotificationsCount] = useState(0);
  const unseenNewOrdersNotificationsCountRef = useRef(unseenNewOrdersNotificationsCount);
  unseenNewOrdersNotificationsCountRef.current = unseenNewOrdersNotificationsCount;
  const [markNotificationsAsSeen] = useMarkNotificationsAsSeenMutation();

  const { user } = useContext(UserContext);
  const { appRole } = user;

  // Get logged-in branch / branches ID
  const pharmacyUserPreferences = useReactiveVar(pharmacyUserPreferencesVar);
  const loggedInBranchIds = pharmacyUserPreferences?.branch.map(branch => branch.id) || [];

  useEffect(() => {
    // Filter notifications based on branchId
    if (appRole !== "ADMIN") {
      const newNotifications = data?.me?.notifications?.edges
        ?.map(notification => notification?.node)
        .filter(notification => {
          const extraData = notification.extraData ? JSON.parse(notification.extraData.replace(/'/g, '"')) : null;
          return loggedInBranchIds.includes(extraData?.branchId);
        });
      setNotifications(newNotifications);
      setTotalCount(newNotifications?.length);
    } else {
      const newNotifications = data?.me?.notifications?.edges?.map(notification => notification?.node);
      setNotifications(newNotifications);
      setTotalCount(newNotifications?.length);
    }
  }, [data]);

  const unReadNotificationsCount = data?.me?.unReadNotificationsCount;

  const pageInfo = data?.me?.notifications?.pageInfo;

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    if (open && unReadNotificationsCount) {
      // If we're closing the dialog, and there's unread notifications, mark notifications as seen
      markNotificationsAsSeen();
      setUnseenNewOrdersNotificationsCount(0);
      setTotalCount(0);
    }
    // Else, we're opening the dialog, do nothing related to marking and Just open the dialog
    setOpen(prevOpen => !prevOpen);
  };
  const onNotificationCreated: TOPIC_LISTENER<AccountNotificationEvent>[
    | EVENT_TOPIC.ORDER_STATUS_UPDATE
    | EVENT_TOPIC.ACCOUNT_NOTIFICATION
    | EVENT_TOPIC.ORDER_DELETED] = useCallback(da => {
    if (
      ("branchId" in da.data && typeof da.data.branchId === "string" && loggedInBranchIds.includes(da.data?.branchId)) ||
      appRole === "ADMIN"
    ) {
      refetch();
      // getNotifications();
    }
  }, []);

  useSubscribeToNotificationTopic(EVENT_TOPIC.ACCOUNT_NOTIFICATION, onNotificationCreated);
  useSubscribeToNotificationTopic(EVENT_TOPIC.ORDER_STATUS_UPDATE, onNotificationCreated);
  useSubscribeToNotificationTopic(EVENT_TOPIC.ORDER_DELETED, onNotificationCreated);

  useEffect(() => {
    open && unReadNotificationsCount && refetch();
  }, [open]);

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 5,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev: MeNotificationsQuery, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          return {
            ...fetchMoreResult,
            me: {
              ...fetchMoreResult?.me,
              notifications: {
                ...fetchMoreResult?.me?.notifications,
                edges: [...(prev?.me?.notifications?.edges || []), ...(fetchMoreResult?.me?.notifications?.edges || [])],
              },
            },
          };
        },
      });
    }
  };

  const onOrderStatusChanged: TOPIC_LISTENER<OrderStatusChangedNotificationEvent>[EVENT_TOPIC.ORDER_STATUS_UPDATE] = useCallback(
    ({ data }) => {
      if (loggedInBranchIds.includes(data?.branchId) || appRole === "ADMIN") {
        window.dispatchEvent(new CustomEvent<OrderStatusChangedNotificationEvent["data"]>("orderStatusChanged", { detail: data }));
        const orderStatus = data?.status as OrderStatus;
        if (areStatuesEqual(orderStatus, OrderStatus.NewRequest)) {
          unseenNewOrdersNotificationsCountRef.current += 1;
          const message = t(unseenNewOrdersNotificationsCountRef.current === 1 ? "You have new order" : "You have {{ value }} new orders", {
            value: unseenNewOrdersNotificationsCountRef.current,
          });
          playAudio("newOrderNotification.wav");
          notify({ message }, () => {
            // window.open(`${location.protocol}//${location.host}/orders`, "_blank");
          });
          setUnseenNewOrdersNotificationsCount(unseenNewOrdersNotificationsCountRef.current);
        } else if (areStatuesEqual(orderStatus, OrderStatus.Allocated)) {
          playAudio("orderAllocatedNotification.mp3");
        }
      }
    },
    []
  );

  useSubscribeToNotificationTopic(EVENT_TOPIC.ORDER_STATUS_UPDATE, onOrderStatusChanged);

  return {
    open,
    loading,
    pageInfo,
    totalCount,
    notifications,
    unReadNotificationsCount: (unReadNotificationsCount || 0) + unseenNewOrdersNotificationsCount,
    unseenNewOrdersNotificationsCount,
    handleToggle,
    fetchMoreData,
    autoplayBlocked,
    handleAllowSound,
  };
};
