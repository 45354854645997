import { UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { BranchError, formatGraphQLError, formatMessageErrors, useCreateBranchMutation } from "@health/queries";
import { doesBranchHasAcceptDeliveryFlag, doesBranchHasAcceptPickupFlag } from "@health/smart-ui";
import { useAddToast } from "@health/ui";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useBranchNewPageHook = () => {
  const { t } = useTranslation("vendor");
  const navigate = useNavigate();
  const [errors, setError] = useState<BranchError[]>();
  const { user } = useContext(UserContext);
  const { succeeded, failed } = useAddToast();

  const [createBranch] = useCreateBranchMutation({
    onError: ({ graphQLErrors }) => {
      const formattedErrors = formatGraphQLError(graphQLErrors);
      failed(t(formattedErrors));
    },
  });

  const handleCreateBranch = request => {
    createBranch({
      variables: {
        vendorId: user.id as string,
        branch: {
          ...request,
          acceptsDelivery: doesBranchHasAcceptDeliveryFlag(request?.type) && request?.acceptsDelivery,
          acceptsPickup: doesBranchHasAcceptPickupFlag(request?.type) && request?.acceptsPickup,
          branchDivision: 0,
        },
      },
    }).then(response => {
      const branchErrors = response?.data?.branchCreate?.branchErrors;
      if (branchErrors?.length === 0) {
        succeeded(t("Branch Create Successfully"));
        navigate(-1);
      } else {
        const formattedErrors = formatMessageErrors(branchErrors);
        failed(t(formattedErrors));
        setError(branchErrors as BranchError[]);
      }
    });
  };

  return {
    errors,
    handleCreateBranch,
  };
};
