import { OrderStatusEnum } from "@health/queries";
import { OrderColorEnum } from "@health/ui";

export enum ORDERS_STATUS {
  NEW = "new",
  PENDING = "pending",
  DISPENSING = "dispensing",
  DELIVERING = "delivering",
}

export const statuses = {
  new: [OrderStatusEnum.NewRequest],
  pending: [OrderStatusEnum.Allocated, OrderStatusEnum.AcceptedByProvider, OrderStatusEnum.ErxHubAuthSubmitTimedOut],
  dispensing: [
    OrderStatusEnum.ReadyForCustomerPickup,
    OrderStatusEnum.ErxHubFullyApproved,
    OrderStatusEnum.ErxHubPartiallyApproved,
    OrderStatusEnum.ErxHubRejected,
    OrderStatusEnum.InProgress,
    OrderStatusEnum.ErxHubTimedOut,
    OrderStatusEnum.ErxHubAuthRequestFailed,
    OrderStatusEnum.WaitingErxHubApproval,
    OrderStatusEnum.WaitingPatientConfirmation,
  ],
  delivering: [
    OrderStatusEnum.Delivered,
    OrderStatusEnum.OutForDelivery,
    OrderStatusEnum.Dispensed,
    OrderStatusEnum.ReadyForDelivery,
    OrderStatusEnum.Returned,
  ],
};

export const mapStatusToColor = {
  [ORDERS_STATUS.NEW]: OrderColorEnum.primary,
  [ORDERS_STATUS.PENDING]: OrderColorEnum.warning,
  [ORDERS_STATUS.DISPENSING]: OrderColorEnum.success,
  [ORDERS_STATUS.DELIVERING]: OrderColorEnum.info,
};

export const mapStatusToName = status => {
  return status?.charAt(0)?.toUpperCase() + status?.slice(1);
};
