import { ReactiveVar } from "@apollo/client";
import { useRef, useState } from "react";

type playAudioHookProps = {
  notificationSoundFiles: ReactiveVar<Record<string, HTMLAudioElement>>;
};
export const usePlayAudioHook = (props: playAudioHookProps) => {
  const { notificationSoundFiles } = props;
  const [autoplayBlocked, setAutoplayBlocked] = useState(false);
  const lastAttemptedFile = useRef<string | null>(null);

  function playAudio(file: string) {
    if (!notificationSoundFiles()[file]) {
      notificationSoundFiles({
        ...notificationSoundFiles(),
        [file]: new Audio(file),
      });
    }

    const audio = notificationSoundFiles()[file];

    if (audio) {
      audio.play().catch(() => {
        setAutoplayBlocked(true);
        lastAttemptedFile.current = file;
      });
    }
  }

  function handleAllowSound() {
    setAutoplayBlocked(false);

    if (lastAttemptedFile.current) {
      const audio = notificationSoundFiles()[lastAttemptedFile.current];
      if (audio) {
        audio.play().catch(() => {
          console.error("Failed to play audio even after user interaction.");
        });
      }
      lastAttemptedFile.current = null;
    }
  }

  return { autoplayBlocked, handleAllowSound, playAudio };
};
