import { OrderDeliveryStatusEnum, OrderStatus } from "@health/queries";
import moment from "moment";

export const height = 700;

const erxHubStatuses = [
  OrderStatus.WaitingErxHubApproval,
  OrderStatus.ErxHubFullyApproved,
  OrderStatus.ErxHubPartiallyApproved,
  OrderStatus.ErxHubTimedOut,
  OrderStatus.ErxHubRejected,
  OrderStatus.InProgress,
  OrderStatus.CanceledByProvider,
  OrderStatus.ErxHubClaimApproved,
  OrderStatus.WaitingErxHubClaimApproval,
  OrderStatus.ErxHubClaimRejected,
  OrderStatus.ErxHubClaimFailed,
  OrderStatus.ErxHubAuthRequestFailed,
  OrderStatus.ErxHubClaimSubmitTimedOut,
];
export const shouldRefetchPending = (status: OrderStatus | undefined) => {
  const pendingStatuses = [OrderStatus.Allocated, OrderStatus.AcceptedByProvider, OrderStatus.ReadyForCustomerPickup, ...erxHubStatuses];
  return !!status && pendingStatuses.includes(status);
};
export const shouldRefetchNew = (status: OrderStatus | undefined) => {
  return [
    OrderStatus.NewRequest,
    OrderStatus.InProgress,
    OrderStatus.RejectedByProvider,
    OrderStatus.Allocated,
    OrderStatus.AcceptedByProvider,
  ].includes(status!);
};

export const shouldRefetchDispensing = (status: OrderStatus | undefined) => {
  // i think this must be [OrderStatus.ReadyForCustomerPickup, ...erxHubCliamStatuses] not erxHubStatuses
  return [
    OrderStatus.ReadyForCustomerPickup,
    OrderStatus.OutForDelivery,
    OrderStatus.WaitingPatientConfirmation,
    OrderStatus.Delivered,
    OrderStatus.Dispensed,
    OrderStatus.FailedToDispense,
    ...erxHubStatuses,
  ].includes(status!);
};
export const shouldRefetchDelivered = (deliveryStatus: string | undefined, orderStatus: OrderStatus | undefined) => {
  const deliveryStatuses = [
    OrderDeliveryStatusEnum.CarrierAllocated,
    OrderDeliveryStatusEnum.DriverAcceptedOrder,
    OrderDeliveryStatusEnum.DriverIsAboutToArrive,
    OrderDeliveryStatusEnum.DriverPickedUpOrder,
    OrderDeliveryStatusEnum.OrderIsCancelled,
    OrderDeliveryStatusEnum.OrderIsDelivered,
    OrderDeliveryStatusEnum.OrderIsResumed,
    OrderDeliveryStatusEnum.OrderIsSuspended,
    OrderDeliveryStatusEnum.Failed,
    OrderDeliveryStatusEnum.Pending,
    OrderDeliveryStatusEnum.Returned,
    OrderDeliveryStatusEnum.PickedByConsumer,
  ];
  const orderStatuesDelivering = [
    OrderStatus.Delivered,
    OrderStatus.OutForDelivery,
    OrderStatus.Dispensed,
    OrderStatus.CanceledByProvider,
    OrderStatus.ReadyForCustomerPickup,
    OrderStatus.ErxHubClaimApproved,
    OrderStatus.ErxHubFullyApproved,
  ];
  return [...deliveryStatuses].includes(deliveryStatus! as OrderDeliveryStatusEnum) || orderStatuesDelivering.includes(orderStatus!);
};

export const formatDeliveryTimeSlot = (startTime: string, endTime: string, lang: string) => {
  const deliveryStartTime = moment(startTime, "HH:mm:ss");
  const deliveryEndTime = moment(endTime, "HH:mm:ss");
  return `${deliveryStartTime.locale("en").format("hh:mm") + deliveryStartTime.locale(lang).format("A")} -
  ${deliveryEndTime.locale("en").format("hh:mm") + deliveryEndTime.locale(lang).format("A")}`;
};

export const formatTimeSlotForColumn = (time: string, lang: string) => {
  const slotTime = moment(time, "HH:mm:ss");
  return slotTime.locale(lang).format("hh:mmA");
};
