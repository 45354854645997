import { deepViolet, makeStyles, white } from "@health/ui";

export const useOrdersViewStyles = makeStyles<{ selectedView: string }>()((_, { selectedView }) => ({
  flex: {
    display: "flex",
    boxShadow: "0px 7px 29px #64646F33",
    border: "1px solid #60779526",
    borderRadius: 10,
    height: 37,
    alignItems: "center",
  },
  selectedButtonTabs: {
    margin: 0,
    fontSize: 12,
    whiteSpace: "nowrap",
    height: selectedView === "Tabs" ? 40 : 35,
    color: selectedView === "Tabs" ? `${white} !important` : `${deepViolet} !important`,
    background: selectedView !== "Tabs" ? `${white} !important` : `${deepViolet} !important`,
    "&:hover": {
      boxShadow: "none",
    },
  },
  selectedButtonQueue: {
    margin: 0,
    fontSize: 12,
    whiteSpace: "nowrap",
    height: selectedView !== "Tabs" ? 40 : 35,
    color: selectedView !== "Tabs" ? "white !important" : `${deepViolet} !important`,
    background: selectedView === "Tabs" ? "white !important" : `${deepViolet} !important`,
    "&:hover": {
      boxShadow: "none",
    },
  },
}));
