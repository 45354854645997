import { useTranslation } from "@health/i18n";
import { Autocomplete, Button, Checkbox, CircularProgress, CustomMuiDialog, Grid, TextField, Tooltip, Typography } from "@health/ui";
import React, { FC, Fragment, useState } from "react";
import { useOrderActionStyles } from "../actions/OrderAction.styles";
import { useDrugIdentification } from "./DrugIdentification.hook";
import { useMedicationsMappingHook } from "./MedicationsMapping.hook";
import { MedicationsMappingDialogContentWrapper } from "./MedicationsMappingDialogContentWrapper";
import { MedicationsMappingDialogProps } from "./types";

export const MedicationsMappingDialog: FC<MedicationsMappingDialogProps> = ({
  isLoading,
  buttonLabel,
  medications,
  referenceNumber,
  patientIdentifier,
  branchId,
  onSubmit: handleAccept,
}) => {
  const { classes } = useOrderActionStyles();
  const { t } = useTranslation("vendor");
  const [open, setOpen] = useState<boolean>(false);

  const { loading, medicationsData, acceptedMedications, errors, tradeDrug, handleValue, handleQuantity, handleSelectedValue } =
    useMedicationsMappingHook(medications, referenceNumber, patientIdentifier, branchId, open);

  const { handleMedication, getGenericDrugProperties } = useDrugIdentification();

  const hasInvalidQuantity = acceptedMedications.find(
    currentItem =>
      currentItem?.quantity > currentItem?.quantityItem ||
      currentItem?.quantity < 0 ||
      currentItem.quantity == "" ||
      Number(currentItem.quantity) === 0
  );

  const helpText = currentItem => {
    if (currentItem?.quantity > currentItem?.quantityItem) {
      return currentItem?.quantityItem ? t("please enter quantity less than or equal") + " " + `${currentItem?.quantityItem}` : "";
    } else if (currentItem?.quantity == 0) {
      return t(`Please enter quantity larger than 0`);
    } else return currentItem?.quantity < 0 ? t(`Invalid quantity`) : "";
  };

  const inputQuantity = (currentItem, medication) => {
    if (currentItem) {
      if (currentItem?.quantity) {
        return currentItem?.quantity;
      } else return currentItem?.isTouch ? undefined : currentItem?.quantityItem;
    } else return medication?.tradeDrugs?.[0]?.quantity;
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleConfirm = () => {
    handleToggle();
    handleAccept(medicationsData);
  };

  const buttonLabels = buttonLabel === t("View & Accept");

  return (
    <CustomMuiDialog
      open={open}
      button={
        <Tooltip title={buttonLabel}>
          <Button
            className={!buttonLabels ? classes.outlinedAccept : classes.accept}
            variant={buttonLabels ? "contained" : "outlined"}
            onClick={handleToggle}
          >
            {buttonLabel}
          </Button>
        </Tooltip>
      }
      DialogTitleProps={{
        title: t("Medications"),
        onClose: handleToggle,
      }}
      onClose={handleToggle}
      DialogActionsProps={{
        children: (
          <Button
            startIcon={isLoading && <CircularProgress size={12} />}
            disabled={Boolean(!acceptedMedications.length) || hasInvalidQuantity || isLoading}
            onClick={handleConfirm}
          >
            {t("Confirm")}
          </Button>
        ),
      }}
    >
      <MedicationsMappingDialogContentWrapper loading={loading} isEmpty={!tradeDrug?.length} errors={errors}>
        <Grid container spacing={2} justifyContent='center'>
          {Boolean(tradeDrug?.length) &&
            tradeDrug?.map(medication => {
              const currentItem = acceptedMedications.find(item => item?.lineId === medication?.lineId);
              const helperText = helpText(currentItem);
              const inputValueQuantity = inputQuantity(currentItem, medication);

              const item = getGenericDrugProperties(medication);
              const getItem = handleMedication(item);

              return (
                <Fragment key={medication?.lineId}>
                  <Grid item xs={1}>
                    <Checkbox checked={currentItem?.isChecked} onChange={e => handleSelectedValue(e, medication?.lineId)} />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField disabled fullWidth size='small' label={t("Generic Name")} defaultValue={medication?.display} />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      disabled={!currentItem?.isChecked}
                      options={(medication.tradeDrugs as any) || []}
                      defaultValue={medication?.tradeDrugs?.[0]}
                      onChange={(e, value) => handleValue(e, value, medication?.lineId)}
                      getOptionLabel={(option: { display: string }) => option?.display}
                      renderOption={(props: any, option) => (
                        <div {...props}>
                          <Typography color={getItem.color} fontWeight={getItem.weight}>
                            {option.display}
                          </Typography>
                        </div>
                      )}
                      renderInput={params => <TextField {...params} size='small' label={t("Trade Name")} />}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      type='number'
                      size='small'
                      label={t("Quantity")}
                      value={inputValueQuantity}
                      error={
                        currentItem?.quantityItem
                          ? currentItem?.quantity > currentItem?.quantityItem ||
                            currentItem?.quantity < 0 ||
                            Number(currentItem?.quantity) === 0
                          : false
                      }
                      disabled={!currentItem?.isChecked}
                      helperText={helperText}
                      InputProps={{
                        inputProps: {
                          min: 1,
                        },
                      }}
                      onChange={e => handleQuantity(e, medication?.lineId)}
                    />
                  </Grid>
                </Fragment>
              );
            })}
        </Grid>
      </MedicationsMappingDialogContentWrapper>
    </CustomMuiDialog>
  );
};
