/* eslint-disable max-statements */
import { OrderDataFragment, OrderSource, OrderStatusEnum, OrderTypesEnum } from "@health/queries";
import { statuses } from "../statuses";
import {
  getSharedOrderData,
  mapOrderToDeliveredColumn,
  mapOrderToDispensingColumn,
  mapOrderToNewColumn,
  mapOrderToPendingColumn,
} from "./mapOrderToColumn";
import { shouldShowCancelButton, shouldShowResubmitButton } from "./utils";

export const mapOrderToColumnByStatus = (order: OrderDataFragment, time, t) => {
  const news = mapOrderToNewColumn(order, time, t);
  const pending = mapOrderToPendingColumn(order, time, t);
  const dispensing = mapOrderToDispensingColumn(order, time, t);
  const delivered = mapOrderToDeliveredColumn(order, time, t);
  const status = String(order?.status) as OrderStatusEnum;

  if (statuses.new.includes(status)) {
    return news;
  } else if (statuses.pending.includes(status)) {
    return pending;
  } else if (statuses.dispensing.includes(status)) {
    return dispensing;
  } else if (statuses.delivering.includes(status)) {
    return delivered;
  } else return getSharedOrderData(order);
};

export const mapOrderToActionByStatus = (order, time, t) => {
  const news = mapOrderToNewColumn(order, time, t);
  const pending = mapOrderToPendingColumn(order, time, t);
  const dispensing = mapOrderToDispensingColumn(order, time, t);
  const delivered = mapOrderToDeliveredColumn(order, time, t);
  const status = String(order?.status) as OrderStatusEnum;
  const isMarketPlaceOrder = order.source == OrderSource.Checkout;
  if (statuses.new.includes(status)) {
    return {
      ...news,
      referenceNumber: order?.prescription?.referenceNumber,
      patientIdentifier: order?.prescription?.patient?.nationalIdNumber,
      branchId: order?.branch?.id,
      action: {
        hasAcceptMarketPlaceOrderButton: !order?.prescription,
        hasAcceptAndViewButton: !!order?.prescription,
        hasReject: true,
      },
    };
  } else if (statuses.pending.includes(status)) {
    return {
      ...pending,
      patientMobile: order?.prescription?.patient?.contactNumber,
      patientName: order?.prescription?.patient?.firstName + " " + order?.prescription?.patient?.lastName,
      patientIdentifier: order?.prescription?.patient?.nationalIdNumber,
      branchId: order?.branch?.id,
      action: {
        hasApproval: status === OrderStatusEnum.Allocated,
        hasReadyForDeliveryButton: isMarketPlaceOrder,
        hasResubmitButton: status === OrderStatusEnum.ErxHubAuthSubmitTimedOut,
        hasCancelButton: status === OrderStatusEnum.ErxHubAuthSubmitTimedOut,
      },
    };
  } else if (statuses.dispensing.includes(status)) {
    const isInAnErxBaseDispenseStatus = [
      OrderStatusEnum.ErxHubPartiallyApproved,
      OrderStatusEnum.ErxHubClaimFailed,
      OrderStatusEnum.ErxHubFullyApproved,
    ].includes(status);

    return {
      ...dispensing,
      patientIdentifier: order?.prescription?.patient?.nationalIdNumber,
      patientName: order?.prescription?.patient?.firstName + " " + order?.prescription?.patient?.lastName,
      patientMobile: order?.prescription?.patient?.contactNumber,
      branchId: order?.branch?.id,
      action: {
        hasCancelButton: shouldShowCancelButton(status),
        hasViewQRButton: !isMarketPlaceOrder && status === OrderStatusEnum.ReadyForCustomerPickup,
        hasReadyForDeliveryButton: isMarketPlaceOrder,
        hasResubmitButton: shouldShowResubmitButton(status),
        hasVerifyAndDispenseButton:
          !isMarketPlaceOrder && status === OrderStatusEnum.ReadyForCustomerPickup && order?.type === OrderTypesEnum.PickupFromPharmacy,
        hasDispenseDeliveryOrderButton: order?.type === OrderTypesEnum.HomeDelivery && !isMarketPlaceOrder && isInAnErxBaseDispenseStatus,
      },
    };
  } else if (statuses.delivering.includes(status)) {
    return {
      ...delivered,
      branchId: order?.branch?.id,
      action: {
        hasViewQRButton: [OrderStatusEnum.Dispensed].includes(status) && order?.type === OrderTypesEnum.PickupFromPharmacy,
        hasViewQRWithAWBButton: [
          OrderStatusEnum.Dispensed,
          OrderStatusEnum.OutForDelivery,
          OrderStatusEnum.Returned,
          OrderStatusEnum.Delivered,
        ].includes(status),
      },
    };
  } else return getSharedOrderData(order);
};
